import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

import VIPChair from '../images/vip-chair/vip.png'
import MagnaChair from '../images/magna-chair/magna.png'
import VSportChair from '../images/vsport-chair/vsport.png'
import TergoChair from '../images/tergo-chair/tergo.png'
import NovoChair from '../images/novo-chair/novo.png'

const items = [
  {
    model: 'Magna',
    productImgLink: MagnaChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016']
  },
  {
    model: 'VIP',
    productImgLink: VIPChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016']
  },
  {
    model: 'VSport',
    productImgLink: VSportChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016']
  },
  {
    model: 'Tergo',
    productImgLink: TergoChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016']
  },
  {
    model: 'Novo',
    productImgLink: NovoChair,
    patents: [
      'PATENT NO.: US 7,950,079 B2',
      'PATENT NO.: US 9,289,353 B2',
      'PATENT NO.: US 9,439,831 B2'
    ],
    issueDate: ['May 31, 2011', 'Mar 22, 2016', 'September 13, 2016']
  }
]

const IPPage = () => {
  return (
    <Layout>
      <SEO
        title="Intellectual Property"
        description="For Contégo patented technologies. Please Refer to virtual patents"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container py-4">
        <h1 className="w-100 text-center py-4">INTELLECTUAL PROPERTY</h1>
        {items.map(item => {
          return (
            <div className="row p-4 my-4 rounded bg-light shadow-sm" key={item.model}>
              <h2 className="w-100 text-center py-2 px-4 text-uppercase shadow-sm rounded">
                {item.model}
              </h2>
              <div className="row">
                <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
                  <img src={item.productImgLink} className="img-fluid" alt="pedicure spa chair" />
                </div>
                <div className="col-md-1" />
                <div className="col-md-4 d-flex flex-column justify-content-center">
                  <h2>PATENTS</h2>
                  {item.patents.map(patent => {
                    return <h5>{patent}</h5>
                  })}
                </div>
                <div className="col-md-4 d-flex flex-column justify-content-center">
                  <h2>ISSUE DATE(s)</h2>
                  {item.issueDate.map(idate => {
                    return <h5>{idate}</h5>
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default IPPage
